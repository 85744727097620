import { FieldValues } from 'react-hook-form'

export function getFormData(fieldValues: FieldValues): FormData {
  const data = new FormData()

  data.append('referrer', window?.location?.href ?? 'unknown referrer')

  if (fieldValues?.mauticform) {
    for (const mautickey in fieldValues.mauticform) {
      data.append(mautickey, fieldValues.mauticform[mautickey])
    }
  } else {
    for (const key in fieldValues) {
      data.append(key, fieldValues[key])
    }
  }

  return data
}

export async function sendFormData(
  action: string,
  formData: FormData,
  json: any
) {
  await fetch(action, {
    method: `POST`,
    body: formData,
  })
    .then(async (response) => {
      try {
        json = await response.json()
      } catch (error) {
        // handle invalid response
        if (error instanceof SyntaxError) {
          // Unexpected token < in JSON
          console.log(
            `There was a SyntaxError in form post request (404/500?)`,
            error
          )
        } else {
          console.log(`There was an error in form post request`, error)
        }
      }
      return json
    })
    .catch((error) => {
      // handle no response
      console.log(`There was an error in form post request`, error)
    })
  return json
}

